import CSS from "csstype";

export const themeProviderStyle: CSS.Properties = {
	borderRight: "1px solid #d9dde1",
	width: "6vw",
	minWidth: "100px",
	justifyContent: "space-between",
};

export const boxStyle = {
	backgroundColor: "#1F85DE",
	display: "flex",
	height: "96vh",
	alignItems: "center",
};

export const tab: CSS.Properties = {
	minWidth: "10",
	outline: "none",
};

export const tabs: CSS.Properties = {
	marginLeft: "auto",
	marginRight: "auto",
};
