//MaterialUI
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
//React
import React from "react";
//Redux
import { useSelector } from "react-redux";
import { store } from "../../../../context/Store";
//Components
import { TreeItems } from "../../TreeItems";
//Styles
import { button } from "../../../../styles/SubTreesStyles";
import { DashboardState } from "../../../../context/DashboardReducer";

interface Props {
	rootNode: any;
	index: number;
}

export const SubTrees: React.FC<Props> = (props: Props) => {
	const activeSubTreeId: number = useSelector<DashboardState>(
		(state) => state.dashboardReducer.activeSubTreeId
	);
	let open: boolean = false;

	const handleClick = () => {
		store.dispatch({ type: "SET_ACTIVE_SUBTREE", payload: props.index });
	};

	if (activeSubTreeId === props.index) {
		open = true;
	}

	return (
		<List
			sx={{ width: "100%", bgcolor: "background.paper", padding: "0px;" }}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<ListItemButton style={button} onClick={handleClick}>
				<ListItemText primary={props.rootNode.name} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>

			<Collapse in={open} timeout="auto" unmountOnExit>
				<div style={{ paddingBottom: "10px" }}>
					<TreeView
						className="tree-view-root"
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
					>
						<TreeItems
							data={props.rootNode.childNodes}
							reducerType={"DASHBOARD"}
						/>
					</TreeView>
				</div>
			</Collapse>
		</List>
	);
};
