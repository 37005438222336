import { ModelNode } from "../../services/ApiService";
import { UseCaseModelsExtensionHelper } from "../../services/UseCaseModelsExtensionHelper";
import { ShowcaseModelSingleton } from "./ShowcaseModelSingleton";
export class UseCaseModelsExtension extends Autodesk.Viewing.Extension {
	models: Autodesk.Viewing.Model[];

	constructor(viewer: any, options: any) {
		super(viewer, options);
		Autodesk.Viewing.Extension.call(this, viewer, options);
		const s1 = UseCaseModelsExtensionHelper.getInstance();
		s1.addExtension(this);
		this.models = [];
	}

	load(): boolean {
		console.log("UseCaseModelsExtension loaded successfully");
		return true;
	}

	unload(): boolean {
		console.log("UseCaseModelsExtension unloaded successfully");
		return true;
	}

	/**
	 * Loads new Models into the viewer and unloads previous models first
	 * @param urns urn of new Models
	 */
	loadNewModels(models: ModelNode[]) {
		this.models.forEach((model) => this.viewer.unloadModel(model));
		this.models = [];
		models.forEach((model) => {
			if (model.urn) this.loadDocument(model.urn);
		});
	}

	/**
	 * Viewer has to be resized when the UseCase Tree is rendered
	 * to fix the offset of the mouse pointer.
	 */
	resizeViewer() {
		this.viewer.resize();
	}

	/**
	 * Initializes a single new model into the viewer and saves them for potential unload.
	 * @param urn urn of model
	 */
	loadDocument(urn: string) {
		Autodesk.Viewing.Document.load(
			urn,
			(doc) => {
				const bubbleNode: Autodesk.Viewing.BubbleNode = doc
					.getRoot()
					.getDefaultGeometry();
				doc.downloadAecModelData().then(() => {
					Autodesk.Viewing.Document.getAecModelData(bubbleNode).then(
						() => {
							let globalOffset =
								this.viewer.model?.getData().globalOffset;
							this.viewer
								.loadDocumentNode(doc, bubbleNode, {
									applyRefpoint: true,
									globalOffset: globalOffset,
									keepCurrentModels: true,
								})
								.then((model) => {
									ShowcaseModelSingleton.getInstance().setModel(
										model
									);
									this.models.push(model);
								});
						}
					);
				});
			},
			(err) => {
				console.log("error loading doc");
			},
			(success: any) => {
				console.log(success);
			}
		);
	}
}
