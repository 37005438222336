import CSS from "csstype";

export const modalStyle: CSS.Properties = {
	position: "absolute",
	top: "50%",
	width: "70vw",
	height: "70vh",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: "24",
};

export const buttonStyle: CSS.Properties = {
	display: "flex",
	color: "#fff",
	height: "4vh",
	backgroundColor: "#1F85DE",
	justifyContent: "center",
};
