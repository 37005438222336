import React from 'react';
//Styles
import '../../styles/App.css'
import { manufacturerPanel } from '../../styles/ProductCatalogStyles';
//Components
import { CatalogModel } from '../../services/ApiService';

interface ProductCatalogProps {
}

interface ProductCatalogState {
    catalog: CatalogModel | null
    categoriesLoading: boolean

}


export class ProductCatalog extends React.Component<ProductCatalogProps, ProductCatalogState> {

    constructor(props: any) {
        super(props)
        this.state = {
            catalog: null,
            categoriesLoading: true,
        };
    }

    componentDidMount() {}

    renderManufacturerPanel = () => {
        return (
            <div>
                <div style={manufacturerPanel}>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container-fluid custom-background">
            </div>
        )
    }
}
