import CSS from "csstype";

export const forgeViewerContainer: CSS.Properties = {
	marginTop: "5vh",
	paddingBottom: "5vh",
};

export const forgeViewer: CSS.Properties = {
	border: "1px solid #1F85DE",
	height: "100%",
	width: "98%",
};

export const useCaseBar: CSS.Properties = {
	marginTop: "5vh",
	marginBottom: "5vh",
	marginLeft: "15px",
};

export const useCaseTree: CSS.Properties = {
	width: "99%",
	height: "100%",
};

export const logoLargeForgeViewer: CSS.Properties = {
	position: "fixed",
	right: 0,
	paddingRight: "2%",
};

export const logoSmallForgeViewer: CSS.Properties = {
	position: "fixed",
	right: 0,
	paddingRight: "4%",
};
