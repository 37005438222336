import React, { useState } from "react";
import { IconButton, Box, Modal, Tooltip } from "@mui/material";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

import { modalStyle, buttonStyle } from "../../../styles/TutorialModalStyles";

interface Props {
	youtubeUrl: string | undefined;
}

/**
 * Component to show a button and a Modal with a Youtube Video in it.
 * How to get the YoutubeUrl: On a Youtube Video click share -> embed
 * -> copy the url from the embed script you get from Youtube
 * -> replace it in .env.local
 */
const TutorialModal = (props: Props) => {
	const [open, setOpen] = useState(false);
	const handleOpen = (e: { currentTarget: { blur: () => void } }) => {
		window.open(props.youtubeUrl);
	};
	const handleClose = () => setOpen(false);
	const [hover, setHover] = useState(false);

	return (
		<div style={buttonStyle}>
			<Tooltip title="Navigieren Sie zum Products4Bim Tutorial.">
				<IconButton
					style={{
						color: hover ? "#00ECC4" : "#fff",
						backgroundColor: "#1F85DE",
						outline: "none",
					}}
					onClick={handleOpen}
					onMouseEnter={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
				>
					<OndemandVideoIcon />
				</IconButton>
			</Tooltip>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box style={modalStyle}>
					<iframe
						width="100%"
						height="100%"
						src={props.youtubeUrl}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</Box>
			</Modal>
		</div>
	);
};

export default TutorialModal;
