import React, { useState } from "react";
import {
	Classification,
	Client,
	Product,
	PropertyDefinitionGroup,
	PropertyStorage,
} from "../../services/ApiService";
import { List } from "@mui/material";
import { InnerList } from "./InnerList";
import { PropertyGroupTableStyles } from "../../styles/PropertyGroupStyles";
import { DashboardState } from "../../context/DashboardReducer";
import { useSelector } from "react-redux";
import { store } from "../../context/Store";
import { SaveIcon } from "../CustomIcons/SaveIcon";
import { InfoIcon } from "../CustomIcons/InfoIcon";
import { SnackbarModel } from "../Snackbar/SnackbarPanel";
import Config from "../../Config";

interface Props {
	classification: Classification;
	product: Product;
}

export const PropertyDefinitonGroupTable = (props: Props) => {
	const client: Client = new Client(Config.BaseUrl);
	const [isLoading, setIsLoading] = useState(true);
	const [propertyDefinitionGroups, setPropertyDefinitionGroups] = useState<
		PropertyDefinitionGroup[]
	>([]);
	const propertyStorages: PropertyStorage[] = useSelector<DashboardState>(
		(state) => state.dashboardReducer.propertyStorage
	);
	const classification: Classification = useSelector<DashboardState>(
		(state) => state.dashboardReducer.classification
	);
	const product: Product = useSelector<DashboardState>(
		(state) => state.dashboardReducer.product
	);

	const handleSaveClick = async () => {
		try {
			await savePropertyStorages(propertyStorages);
		} catch (e: any) {
			console.log("error saving");
		}
		store.dispatch({
			type: "SHOW_SNACKBAR",
			payload: new SnackbarModel(true, "Werte gespeichert.", "success"),
		});
	};

	const handleInfoClick = async () => {};

	const savePropertyStorages = async (propertyStorage: PropertyStorage[]) => {
		await client.saveAllPropertyStorages(propertyStorage);
	};

	if (isLoading && classification.name) {
		client
			.getPropertyDefinitionGroupByClassificationName(
				classification.name!
			)
			.then((propGroup) => {
				setPropertyDefinitionGroups(propGroup);
			});
		setIsLoading(false);
	}

	return (
		<div
			className={"container"}
			style={{ paddingLeft: "0px", paddingRight: "0px" }}
		>
			<h3 style={{ color: "#00ECC4" }}>{product.name} Eigenschaften</h3>
			<div style={PropertyGroupTableStyles}>
				<List
					dense={true}
					style={{ paddingTop: "0px", paddingBottom: "0px" }}
				>
					{propertyDefinitionGroups.map((propGroup, index) => (
						<InnerList
							propertyDefinitionGroup={propGroup}
							product={product}
							key={index}
							productProperties={null}
						/>
					))}
				</List>
			</div>

			<div className={"row"}>
				<div className="col-sm-12">
					<div onClick={handleSaveClick}>
						<SaveIcon />
					</div>
					<div onClick={handleInfoClick}>
						<InfoIcon />
					</div>
				</div>
			</div>
		</div>
	);
};
