const Config = {
	BaseUrl: process.env.REACT_APP_BASEURL,
	DashboardModelUrn: process.env.REACT_APP_DASHBOARD_MODEL_URN,
	AutodeskAuthentication: process.env.REACT_APP_AUTODESK_AUTHENTICATION,
	ClientID: process.env.REACT_APP_CLIENT_ID,
	ClientSecret: process.env.REACT_APP_CLIENT_SECRET,
	YoutubeTutorialUrl: process.env.REACT_APP_YOUTUBE_TUTORIAL_URL,
};

export default Config;
