import { Classification, Product } from "../../services/ApiService";
import { DashboardState } from "../../context/DashboardReducer";
import { useSelector } from "react-redux";
import React from "react";
import { PropertyDefinitonGroupTable } from "./PropertyDefinitonGroupTable";
import { ProductPropertiesTable } from "./ProductPropertiesTable";

export const PropertyDefinitoGroupPanel = () => {
	const selectedProduct: Product = useSelector<DashboardState>(
		(state) => state.dashboardReducer.product
	);
	const classification: Classification = useSelector<DashboardState>(
		(state) => state.dashboardReducer.classification
	);

	if (!selectedProduct) {
		return <div />;
	}

	if (!selectedProduct.isGeneric) {
		return (
			<div className="col-sm-12 px-0 ">
				<ProductPropertiesTable
					classification={classification}
					product={selectedProduct}
				/>
			</div>
		);
	}

	if (!classification) {
		return <div />;
	}
	if (!classification.name) {
		return <div />;
	}

	return (
		<div className="col-sm-12 px-0 ">
			<PropertyDefinitonGroupTable
				classification={classification}
				product={selectedProduct}
			/>
		</div>
	);
};
