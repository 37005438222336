import { FormControl } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import React, { FC } from "react";
import { DashboardState } from "../../../context/DashboardReducer";
import { store } from "../../../context/Store";
import { useSelector } from "react-redux";
import {
	EnumerationValueDescriptor,
	PropertyDefinitionGroup2,
	PropertyElement,
	PropertyStorage,
} from "../../../services/ApiService";

interface Props {
	enumerationValueDescriptor: EnumerationValueDescriptor;
	propertyStorage: PropertyStorage;
	index: number;
	propertyDefinitionGroup: PropertyDefinitionGroup2[] | undefined;
	defaultValue: string;
}

export const EnumerationValueInputForm: FC<Props> = (props: Props) => {
	const propertyStorages: PropertyStorage[] = useSelector<DashboardState>(
		(state) => state.dashboardReducer.propertyStorage
	);
	const getDefaultIndex = () => {
		let defaultIndex: number = -1;
		if (!props.defaultValue) {
			const propertyElement: PropertyElement =
				props.propertyStorage.properties![props.index];

			props.enumerationValueDescriptor!.values!.forEach(
				(value, index) => {
					if (
						value.value?.variant?.value ===
						propertyElement.propertyValue
					) {
						defaultIndex = index;
					}
				}
			);
			return defaultIndex;
		}
		props.enumerationValueDescriptor!.values!.forEach((value, index) => {
			if (value.value?.variant?.value === props.defaultValue) {
				defaultIndex = index;
			}
		});

		return defaultIndex;
	};

	const handleChange = (event) => {
		const index: number = event.target.value;
		if (!props.propertyStorage.properties) return;
		props.propertyStorage.properties[props.index].propertyValue =
			props.enumerationValueDescriptor!.values![
				index
			].value?.variant!.value;
		updatePropertyStorage(props.propertyStorage);
	};

	const updatePropertyStorage = (propertyStorage: PropertyStorage) => {
		const index: number = propertyStorages.findIndex(
			(value) => value.revitID === propertyStorage.revitID
		);
		propertyStorages[index] = propertyStorage;
		store.dispatch({
			type: "SET_PROPERTY_STORAGE",
			payload: propertyStorages,
		});
	};

	getDefaultIndex();

	return (
		<div>
			<FormControl variant="standard">
				<Select
					defaultValue={getDefaultIndex}
					sx={{ minWidth: 140 }}
					onChange={handleChange}
					disabled={props.defaultValue ? true : false}
				>
					{props.enumerationValueDescriptor!.values!.map(
						(value, index) => {
							return (
								<MenuItem value={index} key={value.key}>
									{value.value?.variant?.value}
								</MenuItem>
							);
						}
					)}
				</Select>
			</FormControl>
		</div>
	);
};
