import React from "react";

import { Client, UseCase } from "../../services/ApiService";
import { useSelector } from "react-redux";
import { DashboardState } from "../../context/DashboardReducer";
import { store } from "../../context/Store";
import { SubTrees } from "./Usecases/Trees/SubTrees";
import Config from "../../Config";

interface TreeProps {
	rootTreeNodes: any;
}

/**
 * FC to build the UseCase Tree.
 * @param props active UseCase and catalog Type.
 */
export const Tree: React.FC<TreeProps> = (props) => {
	const [treeNodeIds, setTreeNodeIds] = React.useState<string[]>([]);
	const dashboardUseCase: UseCase = useSelector<DashboardState>(
		(state) => state.dashboardReducer.activeUseCase
	);
	const dashboardTreeNodesLoading: Boolean = useSelector<DashboardState>(
		(state) => state.dashboardReducer.treeNodesLoading
	);

	if (dashboardUseCase) {
		if (dashboardTreeNodesLoading) {
			let apiClient = new Client(Config.BaseUrl);
			apiClient.getUseCaseTree(dashboardUseCase.id!).then((data) => {
				if (data[0][0]) {
					apiClient.getdbIdByTreeNode(data[0][0].id).then((dbIds) => {
						//TreeElementExtensionHelper.getInstance().focusObject(dbIds);
						apiClient
							.getTreeNodeIdsByUseCase(dashboardUseCase.id!)
							.then((treeNodeIds) => {
								setTreeNodeIds(treeNodeIds);
							});
					});
					store.dispatch({
						type: "SET_TREENODES_LOADING",
						payload: false,
					});
				} else {
					store.dispatch({
						type: "SET_TREENODES_LOADING",
						payload: false,
					});
				}
			});
		}
	}

	if (!dashboardUseCase || !props.rootTreeNodes || treeNodeIds.length === 0) {
		return <div />;
	}
	return (
		<div>
			{props.rootTreeNodes.map((rootNode: any, index: number) => (
				<SubTrees rootNode={rootNode} index={index} key={index} />
			))}
		</div>
	);
};
