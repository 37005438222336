//MaterialUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
//React
import React from "react";
import {Client, UseCase} from "../../../services/ApiService";
import {store} from "../../../context/Store";

import {themeProviderStyle, boxStyle, tab, tabs} from "../../../styles/UseCaseBarStyles";
//Load Config File
import Config from "../../../Config";
//Extentions
import {TreeElementExtensionHelper} from "../../../services/TreeElementExtensionHelper";
import TutorialModal from "./TutorialModal";
import {SnackbarModel} from "../../Snackbar/SnackbarPanel";

interface UseCaseBarState {
	usecases: UseCase[];
	selectedUseCase: UseCase;
	usecasesLoading: boolean;
}

const theme = createTheme({
	palette: {
		secondary: {
			main: "#00ECC4",
		},
	},
});

export const UseCaseBar: React.FC = () => {
	const [state, setState] = React.useState<UseCaseBarState>({
		usecases: [],
		selectedUseCase: new UseCase(),
		usecasesLoading: true,
	});

	const handleClick = () => {
		TreeElementExtensionHelper.getInstance().clearView();
	};

	const handleChange = (event: React.SyntheticEvent, useCase: UseCase) => {
		setState({
			usecases: state.usecases,
			selectedUseCase: useCase,
			usecasesLoading: state.usecasesLoading,
		});
		store.dispatch({type: "SET_ACTIVE_USECASE", payload: useCase});
	};

	if (state.usecasesLoading) {
		let apiClient = new Client(Config.BaseUrl);
		apiClient
			.getAllUsecases()
			.then((data) =>
				setState({
					usecases: data,
					selectedUseCase: state.selectedUseCase,
					usecasesLoading: false,
				})
			)
			.catch(() => {
				store.dispatch({
					type: "SHOW_SNACKBAR",
					payload: new SnackbarModel(true, "UseCases konnten nicht geladen werden.", "error"),
				});
			});
	}

	return (
		<ThemeProvider theme={theme}>
			<div style={themeProviderStyle}>
				<TutorialModal youtubeUrl={Config.YoutubeTutorialUrl} />
				<Box sx={boxStyle}>
					<Tabs style={tabs} textColor="secondary" indicatorColor="secondary" orientation="vertical" value={state.selectedUseCase} onChange={handleChange} onClick={handleClick}>
						{state.usecases.map((usecase, index) => (
							<Tab
								style={tab}
								disabled={!usecase.isActive}
								key={index}
								value={usecase}
								sx={{
									color: "#fff",
									fontSize: "medium",
									tabSize: "inherit",
									"&:hover": {
										color: "#00ECC4",
									},
								}}
								label={usecase.name}
							/>
						))}
					</Tabs>
				</Box>
			</div>
		</ThemeProvider>
	);
};
