import React, { useState } from "react";
import { Save } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import { button, hoverButton } from "../../styles/PropertyGroupStyles";

export const SaveIcon = () => {
	const [buttonStyle, setButtonStyle] = useState(button);

	const hoverEnter = () => {
		setButtonStyle(hoverButton);
	};

	const hoverLeave = () => {
		setButtonStyle(button);
	};

	return (
		<div>
			<Tooltip title={"Speichert alle Werte"}>
				<IconButton
					style={buttonStyle}
					onMouseEnter={hoverEnter}
					onMouseLeave={hoverLeave}
				>
					<Save />
				</IconButton>
			</Tooltip>
		</div>
	);
};
