import { Tree } from "./Tree";
import React, { useState } from "react";
import { Client, TreeNode, UseCase } from "../../services/ApiService";
import { DashboardState } from "../../context/DashboardReducer";
import { useSelector } from "react-redux";
import { UseCaseModelsExtensionHelper } from "../../services/UseCaseModelsExtensionHelper";
import CSS from "csstype";
import { treeView } from "../../styles/UseCaseTreeStyles";
import Config from "../../Config";

interface State {
  treeNodes: TreeNode[];
  activeUseCase: UseCase | null;
}

const styles: CSS.Properties = {};

export const UseCaseTree: React.FC = () => {
  const [state, setState] = useState<State>({
    treeNodes: [],
    activeUseCase: null,
  });
  const dashboardUseCase: UseCase = useSelector<DashboardState>(
    (state) => state.dashboardReducer.activeUseCase
  );

  const shouldFetchData = () => {
    return !(
      !dashboardUseCase ||
      (state.activeUseCase && state.activeUseCase.id === dashboardUseCase.id)
    );
  };

  const getUseCaseTree = async () => {
    if (!shouldFetchData()) return;
    let apiClient: Client = new Client(Config.BaseUrl);
    apiClient
      .getUseCaseTree(dashboardUseCase.id!)
      .then((treeNodes) => updateState(treeNodes));
  };

  const updateState = (treeNodes: TreeNode[]) => {
    let apiClient: Client = new Client(Config.BaseUrl);
    apiClient.getModelsByUseCase(dashboardUseCase.id!).then((models) => {
      UseCaseModelsExtensionHelper.getInstance().loadNewModels(models);
    });
    if (dashboardUseCase !== state.activeUseCase || !state.activeUseCase) {
      setState({
        treeNodes: treeNodes,
        activeUseCase: dashboardUseCase,
      });
    }
  };

  getUseCaseTree();
  if (dashboardUseCase) {
    UseCaseModelsExtensionHelper.getInstance().resizeViewer();
    return (
      <div className={"generic-tree-view"} style={treeView}>
        {state.treeNodes.map((value: TreeNode, index: number) => (
          <div style={styles} key={index}>
            <Tree key={index} rootTreeNodes={value} />
          </div>
        ))}
      </div>
    );
  }
  return <div />;
};
