import React from "react";
import {TreeElementExtensionHelper} from "../../services/TreeElementExtensionHelper";
import {Client} from "../../services/ApiService";
import {makeStyles} from "@material-ui/core/styles";
import {store} from "../../context/Store";
import {TreeItem} from "@material-ui/lab";
import Config from "../../Config"

interface TreeItemsProps {
	data: any;
	reducerType: string;
}

const useStyles = makeStyles({
	root: {
		color: "#1F85DE",
		"&.Mui-selected > .MuiTreeItem-content": {
			color: "#00ECC4",
			background: "white",
		},
		"&.MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label:hover": {
			color: "#00ECC4",
			background: "white; !important",
		},
	},
});

export const TreeItems: React.FC<TreeItemsProps> = (props) => {
	const apiClient = new Client(Config.BaseUrl);
	const classes = useStyles();

	/**
	 * Focuses the Products in the Dashboard
	 * that are connected to given TreeNode.
	 * @param id TreeNode id.
	 */
	const focusDashboardProducts = (id: number, isGeneric: boolean) => {
		apiClient.getdbIdByTreeNode(id).then((data) => {
			TreeElementExtensionHelper.getInstance().focusObject(data, isGeneric);
		}).catch((error: any) => {
			console.log(error.message)
		});
	};

	/**
	 * Updates product and classification.
	 * @param nodeId Id of Treenode.
	 * @param isGeneric Not generic Products are in a different model document.
	 * @returns void
	 */
	const updateData = (nodeId: string, isGeneric: boolean) => {
		let id: number = Number(nodeId);
		if (isNaN(id)) {
			return;
		}
		store.dispatch({type: "SET_PRODUCT", payload: null});
		focusDashboardProducts(id, isGeneric);
		apiClient.getproductbytreenodeid(id).then((product) => {
			store.dispatch({type: "SET_PRODUCT", payload: product});
			if (product) {
				TreeElementExtensionHelper.getInstance().getClassificationByProduct(product);
			}
		}).catch((error: any) => {
			console.log("ERROR:", error.message)
		});
	};

	return (
		<div>
			{props.data &&
				props.data.map((item, index) => (
					<div key={index}>
						<TreeItem className={classes.root} key={item.id} id={item.id.toString()} nodeId={item.id} label={item.name} onClick={() => updateData(item.id, item.isGeneric)}>
							{item.childNodes && <TreeItems data={item.childNodes} reducerType={props.reducerType} />}
						</TreeItem>
					</div>
				))}
		</div>
	);
};
