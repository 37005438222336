import {TreeElementExtension} from "../components/extensions/TreeElementExtension";
import {Product} from "./ApiService";

export class TreeElementExtensionHelper {
	private static instance: TreeElementExtensionHelper;
	extension: TreeElementExtension | undefined;

	static getInstance(): TreeElementExtensionHelper {
		if (!TreeElementExtensionHelper.instance) {
			TreeElementExtensionHelper.instance = new TreeElementExtensionHelper();
		}
		return TreeElementExtensionHelper.instance;
	}

	addExtension(extension: TreeElementExtension) {
		this.extension = extension;
	}

	focusObject(dbIds: number[], isGeneric: boolean) {
		this.extension?.focusObject(dbIds, isGeneric);
	}

	fitToView(dbIds: number[]) {
		this.extension?.fitToView(dbIds);
	}

	clearView() {
		this.extension?.clearView();
	}

	/**
	 *
	 * @param product
	 */
	getClassificationByProduct(product: Product) {
		this.extension?.getClassificationByProduct(product);
	}
}
