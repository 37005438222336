import React from 'react';
import Dashboard from './components/Dashboard/Dashboard';
import { ProductCatalog } from './components/ProductCatalog/ProductCatalog';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './context/Store'

//Styles
import './styles/App.css';

const App = () => {

  return (
    <Router>
      <Provider store={store}>
        <Route path="/" exact component={Dashboard} />
        <Route path="/productcatalog" render={() => (
          <ProductCatalog />

        )} />
      </Provider>
    </Router>
  )
}

export default App;
