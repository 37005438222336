import { SnackbarModel } from "../components/Snackbar/SnackbarPanel";
import {
	Classification,
	UseCase,
	Product,
	PropertyDefinition,
	PropertyStorage,
} from "../services/ApiService";

export interface DashboardState {
	classification: Classification | null;
	classifications: PropertyDefinition[];
	propertySets: any;
	activeUseCase: UseCase | null;
	treeNodesLoading: Boolean;
	product: Product | null;
	propertyStorage: PropertyStorage[];
	activeSubTreeId: number | null;
	snackbar: SnackbarModel;
	externalId: string;
	viewerInitialized: boolean;
}

const initialState = {
	classification: null,
	classifications: [],
	propertySets: null,
	activeUseCase: null,
	treeNodesLoading: true,
	product: null,
	propertyStorage: [],
	activeSubTreeId: null,
	snackbar: new SnackbarModel(false, "", "success"),
	externalId: "",
	viewerInitialized: false,
};

type ClassificationAction = {
	type: "SET_CLASSIFICATION";
	payload: Classification;
};
type SetClassificationsAction = {
	type: "SET_CLASSIFICATIONS";
	payload: Classification[];
};
type PropertySetsAction = { type: "SET_PROPERTYSETS"; payload: any };
type SetActiveUseCaseAction = { type: "SET_ACTIVE_USECASE"; payload: UseCase };
type SetTreeNodesLoading = { type: "SET_TREENODES_LOADING"; payload: Boolean };
type SetProductAction = { type: "SET_PRODUCT"; payload: Product | null };
type SetPropertyDefinitionString = {
	type: "SET_PROPERTY_STRING";
	payload: PropertyDefinition;
};
type SetPropertyStorage = {
	type: "SET_PROPERTY_STORAGE";
	payload: PropertyStorage[];
};
type SetExternalId = { type: "SET_EXTERNAL_ID"; payload: string };
type SetActiveSubTree = { type: "SET_ACTIVE_SUBTREE"; payload: number };
type ShowSnackbar = { type: "SHOW_SNACKBAR"; payload: SnackbarModel };
type InitializeViewer = { type: "VIEWER_INITIALIZED"; payload: boolean };

type Action =
	| ClassificationAction
	| PropertySetsAction
	| SetActiveUseCaseAction
	| SetTreeNodesLoading
	| SetProductAction
	| SetPropertyDefinitionString
	| SetClassificationsAction
	| SetPropertyStorage
	| SetActiveSubTree
	| ShowSnackbar
	| SetExternalId
	| InitializeViewer;

export const dashboardReducer = (
	state: DashboardState = initialState,
	action: Action
) => {
	switch (action.type) {
		case "SET_CLASSIFICATION": {
			return {
				classification: action.payload,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		}

		case "SET_PROPERTYSETS": {
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: action.payload,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		}

		case "SET_ACTIVE_USECASE": {
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: action.payload,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		}
		case "SET_TREENODES_LOADING": {
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: action.payload,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		}
		case "SET_PRODUCT": {
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: action.payload,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		}
		case "SET_PROPERTY_STRING":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		case "SET_CLASSIFICATIONS":
			return {
				classification: state.classification,
				classifications: action.payload,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		case "SET_PROPERTY_STORAGE":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: action.payload,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		case "SET_ACTIVE_SUBTREE":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: action.payload,
				snackbar: state.snackbar,
				viewerInitialized: state.viewerInitialized,
			};
		case "SHOW_SNACKBAR":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: action.payload,
				viewerInitialized: state.viewerInitialized,
			};
		case "SET_EXTERNAL_ID":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				externalId: action.payload,
				viewerInitialized: state.viewerInitialized,
			};
		case "VIEWER_INITIALIZED":
			return {
				classification: state.classification,
				classifications: state.classifications,
				propertySets: state.propertySets,
				activeUseCase: state.activeUseCase,
				treeNodesLoading: state.treeNodesLoading,
				product: state.product,
				propertyStorage: state.propertyStorage,
				activeSubTreeId: state.activeSubTreeId,
				snackbar: state.snackbar,
				externalId: state.externalId,
				viewerInitialized: action.payload,
			};
		default: {
			return state;
		}
	}
};
