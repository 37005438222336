import {Classification, Product} from "../../services/ApiService";
import {TreeElementExtensionHelper} from "../../services/TreeElementExtensionHelper";
import {store} from "../../context/Store";
import {ShowcaseModelSingleton} from "./ShowcaseModelSingleton";

export class TreeElementExtension extends Autodesk.Viewing.Extension {
	constructor(viewer: any, options: any) {
		super(viewer, options);
		Autodesk.Viewing.Extension.call(this, viewer, options);
		const s1 = TreeElementExtensionHelper.getInstance();
		s1.addExtension(this);
	}

	load(): boolean {
		return true;
	}

	unload(): boolean {
		return true;
	}

	focusObject(dbIds: number[], isGeneric: boolean) {
		if (isGeneric) {
			this.viewer.fitToView(dbIds);
			this.viewer.isolate(dbIds);
			return;
		}
		const model: Autodesk.Viewing.Model | undefined = ShowcaseModelSingleton.getInstance().getModel();
		if (model) {
			this.viewer.fitToView(dbIds, model);
			this.viewer.isolate(dbIds, model);
			this.viewer.select(dbIds, model);
		}
	}

	fitToView(dbIds: number[]) {
		this.viewer.show(dbIds);
		this.viewer.fitToView(dbIds);
	}

	clearView() {
		this.viewer.clearSelection();
		this.viewer.isolate();
	}

	/**
	 *
	 * @param product
	 */
	getClassificationByProduct(product: Product) {
		this.viewer.clearSelection();
		const currentSelection = this.viewer.getSelection();
		if (product.dbId != null) {
			currentSelection.push(product.dbId);
		}
		this.viewer.select(currentSelection);
		for (let id of this.viewer.getSelection()) {
			this.viewer.getProperties(id, this.retrieveProps);
		}
	}

	retrieveProps(data) {
		const classificationDisplayName = "p4bClassification";
		data.properties.map((data) => {
			if (data.displayName === classificationDisplayName) {
				store.dispatch({type: "SET_CLASSIFICATION", payload: new Classification({name: data.displayValue})});
				return;
			}
		});
	}
}
