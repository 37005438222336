import CSS from "csstype";

export const PropertyGroupTableStyles: CSS.Properties = {
	overflowY: "auto",
	maxHeight: "32vh",
	color: "#1F85DE",
	border: "1px solid #1F85DE",
};

export const button: CSS.Properties = {
	color: "#1F85DE",
	float: "right",
	outline: "0",
	border: "none"
};

export const hoverButton: CSS.Properties = {
	color: "#00ECC4",
	float: "right",
	outline: "none"
};

export const checkBox: CSS.Properties = {
	color: "#1F85DE",
};

export const propertiesButton: CSS.Properties = {
	color: "#00ECC4",
	fontSize: "18.72px",
	outline: "none",
}