import React from "react";
import {Alert, Snackbar} from "@mui/material";
import {DashboardState} from "../../context/DashboardReducer";
import {useSelector} from "react-redux";
import {store} from "../../context/Store";

export class SnackbarModel {
	show: boolean;
	message: string;
	severity: "info" | "warning" | "error" | "success";

	constructor(show: boolean, message: string, severity: "info" | "warning" | "error" | "success") {
		this.show = show;
		this.message = message;
		this.severity = severity;
	}
}

export const SnackbarPanel = () => {
	const snackbar: SnackbarModel = useSelector<DashboardState>((state) => state.dashboardReducer.snackbar);

	const handleRefreshClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		store.dispatch({type: "SHOW_SNACKBAR", payload: new SnackbarModel(false, "", "success")});
	};

	return (
		<Snackbar open={snackbar.show} autoHideDuration={6000} onClose={handleRefreshClose}>
			<Alert onClose={handleRefreshClose} severity={snackbar.severity} sx={{width: "100%"}}>
				{snackbar.message}
			</Alert>
		</Snackbar>
	);
};
