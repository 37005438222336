import React from "react";
//Styles
import "../../styles/App.css";
//Components
import { UseCaseTree } from "./UseCaseTree";
import { UseCaseBar } from "./Usecases/UseCaseBar";
import ForgeViewer from "../ForgeViewer/ForgeViewer";

import ekkodale_logo from "../../graphics/ekkodale_logo.png";
import { useSelector } from "react-redux";

import {
  forgeViewer,
  forgeViewerContainer,
  useCaseBar,
  useCaseTree,
  logoSmallForgeViewer,
} from "../../styles/DashboardStyles";
import { DashboardState } from "../../context/DashboardReducer";
import { UseCase } from "../../services/ApiService";
import { PropertyDefinitoGroupPanel } from "../PropertyDefiitonGroupPanel/PropertyDefinitoGroupPanel";
import { propertyPanel } from "../../styles/PropertyPanelStyles";
import { SnackbarPanel } from "../Snackbar/SnackbarPanel";
import Config from "../../Config";

function Dashboard() {
  const dashboardUseCase: UseCase = useSelector<DashboardState>(
    (state) => state.dashboardReducer.activeUseCase
  );
  const viewerInitialized: boolean = useSelector<DashboardState>(
    (state) => state.dashboardReducer.viewerInitialized
  );
  const dashboardModelUrn: string = Config.DashboardModelUrn as string;
  if (dashboardUseCase) {
    return (
      <div className="container-fluid custom-background">
        <div className="row fill ">
          <div className="col-sm-0">
            <UseCaseBar />
          </div>

          <div className="col-sm-3 px-0" style={useCaseBar}>
            <div className="col-sm-12 usecase-panel">
              <div className="px-0 row" style={useCaseTree}>
                <UseCaseTree />
              </div>
              <div className={"px-0 row align-items-end"} style={propertyPanel}>
                <PropertyDefinitoGroupPanel />
              </div>
            </div>
          </div>
          <div className="col-sm-8 px-0" style={forgeViewerContainer}>
            <div className="col-sm-12 px-0" style={forgeViewer}>
              <ForgeViewer urn={dashboardModelUrn} />
            </div>
            <div className="col-sm-12 text-right">
              <img
                src={ekkodale_logo}
                alt="ekkodale logo"
                style={logoSmallForgeViewer}
              />
            </div>
          </div>
        </div>
        <SnackbarPanel />
      </div>
    );
  }

  if (viewerInitialized) {
    return (
      <div className="container-fluid custom-background">
        <div className="row fill ">
          <div className="col-sm-1 px-0">
            <UseCaseBar />
          </div>
          <div className="col-sm-0 px-0" />
          <div className="col-sm-11 px-0" style={forgeViewerContainer}>
            <div className="col-sm-12 px-0" style={forgeViewer}>
              <ForgeViewer urn={dashboardModelUrn} />
            </div>
            <div className="col-sm-11 text-right">
              <img
                src={ekkodale_logo}
                alt="ekkodale logo"
                style={logoSmallForgeViewer}
              />
            </div>
          </div>
        </div>
        <SnackbarPanel />
      </div>
    );
  }
  return (
    <div className="container-fluid custom-background">
      <div className="row fill ">
        <div className="col-sm-1 px-0"></div>
        <div className="col-sm-0 px-0" />
        <div className="col-sm-11 px-0" style={forgeViewerContainer}>
          <div className="col-sm-12 px-0" style={forgeViewer}>
            <ForgeViewer urn={dashboardModelUrn} />
          </div>
          <div className="col-sm-11 text-right">
            <img
              src={ekkodale_logo}
              alt="ekkodale logo"
              style={logoSmallForgeViewer}
            />
          </div>
        </div>
      </div>
      <SnackbarPanel />
    </div>
  );
}

export default Dashboard;
