import React from "react";
import { Collapse, ListItemButton, ListItemText } from "@mui/material";
import {
	Client,
	Product,
	ProductProperties,
	PropertyDefinitionGroup,
	PropertyStorage,
} from "../../services/ApiService";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { store } from "../../context/Store";
import { DashboardState } from "../../context/DashboardReducer";
import { useSelector } from "react-redux";
import { PropertyCollapsible } from "./PropertyCollapsible";
import Config from "../../Config";

interface Props {
	propertyDefinitionGroup: PropertyDefinitionGroup;
	product: Product;
	productProperties: ProductProperties | null;
}

export const InnerList = (props: Props) => {
	const [open, setOpen] = React.useState(false);
	const [propertyStorage, setPropertyStorage] =
		React.useState<PropertyStorage>(new PropertyStorage());
	const client: Client = new Client(Config.BaseUrl);
	const propertyStorages: PropertyStorage[] = useSelector<DashboardState>(
		(state) => state.dashboardReducer.propertyStorage
	);

	const handleClick = async () => {
		if (!props.propertyDefinitionGroup.pdgName?.bimq) {
			setOpen(!open);
			return;
		}
		if (open) {
			setOpen(!open);
			return;
		}
		const propertyStore =
			await client.getPropertyStorageByRevitIdAndProductID(
				props.propertyDefinitionGroup.pdgName?.bimq!,
				props.product.id!
			);
		setPropertyStorage(propertyStore);
		addOrUpdatePropertyStorageToStore(propertyStore);
		setOpen(!open);
	};

	const addOrUpdatePropertyStorageToStore = (
		propertyStore: PropertyStorage
	) => {
		const i = propertyStorages.findIndex(
			(value) => value.revitID === propertyStore.revitID
		); //propertyStorages zeigt auf den Store
		if (i >= 0) {
			propertyStorages[i] = propertyStore;
		} else {
			propertyStorages.push(propertyStore);
		}
		store.dispatch({
			type: "SET_PROPERTY_STORAGE",
			payload: propertyStorages,
		});
	};

	return (
		<div>
			<ListItemButton onClick={handleClick} selected={open}>
				<ListItemText
					primary={props.propertyDefinitionGroup.pdgName?.bimq}
				/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				{propertyStorage.revitID ? (
					<PropertyCollapsible
						propertyStorage={propertyStorage}
						propertyDefinitionGroup={props.propertyDefinitionGroup}
						productProperties={props.productProperties}
					/>
				) : (
					<div />
				)}
			</Collapse>
		</div>
	);
};
