import React from "react";
import {
	ProductProperties,
	PropertyElement,
	PropertyStorage,
} from "../../../services/ApiService";
import { useSelector } from "react-redux";
import { checkBox } from "../../../styles/PropertyGroupStyles";
import { DashboardState } from "../../../context/DashboardReducer";
import { store } from "../../../context/Store";
import { TextField } from "@mui/material";
import { Checkbox } from "@mui/material";

interface Props {
	propertyStorage: PropertyStorage;
	index: number;
	productProperties: ProductProperties | null;
	defaultValue: string;
}

export const SingleValueInputForm: React.FC<Props> = (props: Props) => {
	const propertyStorages: PropertyStorage[] = useSelector<DashboardState>(
		(state) => state.dashboardReducer.propertyStorage
	);
	const propertyElement: PropertyElement =
		props.propertyStorage.properties![props.index];
	const valueType = propertyElement?.valueType;

	const handleChange = (event) => {
		if (!props.propertyStorage.properties) return;
		props.propertyStorage.properties[props.index].propertyValue =
			event.target.value;
		updatePropertyStorage(props.propertyStorage);
	};

	const updatePropertyStorage = (propertyStorage: PropertyStorage) => {
		const index: number = propertyStorages.findIndex(
			(value) => value.revitID === propertyStorage.revitID
		);
		propertyStorages[index] = propertyStorage;
		store.dispatch({
			type: "SET_PROPERTY_STORAGE",
			payload: propertyStorages,
		});
	};

	const handleCheckboxChange = (event) => {
		if (propertyElement) {
			propertyElement.propertyValue = event.target.checked ? "YES" : "NO";
		}
	};

	const getCheckboxValue = () => {
		let defaultValue: boolean = false;
		if (propertyElement.propertyValue) {
			defaultValue =
				propertyElement.propertyValue === "YES" ? true : false;
		}
		if (props.defaultValue) {
			defaultValue = props.defaultValue === "YES" ? true : false;
		}
		return defaultValue;
	};

	const getDefaultValue = () => {
		if (props.defaultValue) {
			return props.defaultValue;
		}
		if (propertyElement.propertyValue) {
			return propertyElement.propertyValue;
		}
		return "";
	};

	const defaultValue: string = getDefaultValue();

	switch (valueType) {
		case "TEXT":
			return (
				<TextField
					variant={"standard"}
					defaultValue={defaultValue}
					onChange={handleChange}
					disabled={props.defaultValue !== "" ? true : false}
				/>
			);
		case "INTEGER":
			return (
				<TextField
					variant={"standard"}
					type={"number"}
					defaultValue={Number(defaultValue)}
					onChange={handleChange}
					disabled={props.defaultValue !== "" ? true : false}
				/>
			);
		case "YESNO":
			return (
				<Checkbox
					style={checkBox}
					defaultChecked={getCheckboxValue()}
					onChange={handleCheckboxChange}
					disabled={props.defaultValue !== "" ? true : false}
				/>
			);
	}
	return <div />;
};
