import React, { useState } from "react";
import {
	Classification,
	Client,
	Product,
	ProductProperties,
	PropertyDefinitionGroup,
	PropertyStorage,
} from "../../services/ApiService";
import { Button, List, Tooltip } from "@mui/material";
import { InnerList } from "./InnerList";
import {
	PropertyGroupTableStyles,
	propertiesButton,
} from "../../styles/PropertyGroupStyles";
import { DashboardState } from "../../context/DashboardReducer";
import { useSelector } from "react-redux";
import { store } from "../../context/Store";
import { SaveIcon } from "../CustomIcons/SaveIcon";
import { InfoIcon } from "../CustomIcons/InfoIcon";
import { SnackbarModel } from "../Snackbar/SnackbarPanel";
import Config from "../../Config";

interface Props {
	classification: Classification;
	product: Product;
}

interface ProductPropertiesTableState {
	productProperties: ProductProperties | null;
}

export const ProductPropertiesTable = (props: Props) => {
	const client: Client = new Client(Config.BaseUrl);
	const [isLoading, setIsLoading] = useState(true);
	const [propertyDefinitionGroups, setPropertyDefinitionGroups] = useState<
		PropertyDefinitionGroup[]
	>([]);
	const propertyStorages: PropertyStorage[] = useSelector<DashboardState>(
		(state) => state.dashboardReducer.propertyStorage
	);
	const classification: Classification = useSelector<DashboardState>(
		(state) => state.dashboardReducer.classification
	);
	const product: Product = useSelector<DashboardState>(
		(state) => state.dashboardReducer.product
	);

	const externalId: string = useSelector<DashboardState>(
		(state) => state.dashboardReducer.externalId
	);

	const [productPropertiesState, setProductPropertiesState] =
		React.useState<ProductPropertiesTableState>({
			productProperties: new ProductProperties(),
		});

	const handleSaveClick = async (e) => {
		try {
			await savePropertyStorages(propertyStorages);
		} catch (e: any) {
			console.log("error saving");
		}
		store.dispatch({
			type: "SHOW_SNACKBAR",
			payload: new SnackbarModel(true, "Werte gespeichert.", "success"),
		});
	};

	const handleInfoClick = async (e) => {
		window.open(productPropertiesState.productProperties?.productUrl!);
		e.currentTarget.blur();
	};

	const savePropertyStorages = async (propertyStorage: PropertyStorage[]) => {
		await client.saveAllPropertyStorages(propertyStorage);
	};

	if(classification){
		if (isLoading && classification.name && externalId) {
			client
				.getPropertyDefinitionGroupByClassificationName(
					classification.name!
				)
				.then((propGroup) => {
					setPropertyDefinitionGroups(propGroup);
				});
	
			client
				.getProductPropertiesById(externalId)
				.then((productProps: ProductProperties) => {
					var newProductProperties = new ProductProperties({
						id: productProps.id,
						productUrl: productProps.productUrl,
						propertyDefinitionGroup:
							productProps.propertyDefinitionGroup,
					});
					setProductPropertiesState({
						productProperties: newProductProperties,
					});
				}).catch((error: any) => {
					console.log(error)
				});
			setIsLoading(false);
		}
	}


	return (
		<div
			className={"container"}
			style={{ paddingLeft: "0px", paddingRight: "0px" }}
		>
			<Tooltip title="Navigieren Sie zu den Produktinformationen.">
				<Button
					style={propertiesButton}
					variant="text"
					onClick={handleInfoClick}
				>
					<h3>Eigenschaften: {product.name}</h3>
				</Button>
			</Tooltip>

			<div style={PropertyGroupTableStyles}>
				<List
					dense={true}
					style={{ paddingTop: "0px", paddingBottom: "0px" }}
				>
					{propertyDefinitionGroups.map((propGroup, index) => (
						<InnerList
							propertyDefinitionGroup={propGroup}
							product={product}
							key={index}
							productProperties={
								productPropertiesState.productProperties
							}
						/>
					))}
				</List>
			</div>

			<div className={"row"}>
				<div className="col-sm-12">
					<div onClick={handleSaveClick}>
						<SaveIcon />
					</div>
					<div onClick={handleInfoClick}>
						<InfoIcon />
					</div>
				</div>
			</div>
		</div>
	);
};
