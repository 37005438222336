import { store } from "../../context/Store";
import { Classification } from "../../services/ApiService";

export class ObjectSelectionExtension extends Autodesk.Viewing.Extension {
	constructor(viewer: any, options: any) {
		super(viewer, options);
		Autodesk.Viewing.Extension.call(this, viewer, options);
	}

	load(): boolean {
		this.viewer.addEventListener(
			Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
			this.onSelectionEvent.bind(this)
		);

		return true;
	}

	unload(): boolean {
		return true;
	}

	focusObject(dbIds: number[], model: Autodesk.Viewing.Model) {
		this.viewer.fitToView(dbIds, model);
		this.viewer.isolate(dbIds);
	}

	focus(dbIds: number[]) {
		this.viewer.fitToView(dbIds);
		this.viewer.isolate(dbIds);
	}

	onSelectionEvent(selection: {
		selections: {
			model: Autodesk.Viewing.Model;
			fragIdsArray: number[];
			dbIdArray: number[];
		}[];
	}) {
		try {
			if (selection.selections[0].dbIdArray.length < 5) {
				selection.selections[0].model.getProperties(
					selection.selections[0].dbIdArray[0],
					this.onSuccessCallback,
					this.errorCallback
				);
			} else {
				store.dispatch({
					type: "SET_CLASSIFICATION",
					payload: new Classification({
						name: "Tor",
					}),
				});
				console.log("no classification found");
			}
		} catch (exception: any) {
			console.log("selection error");
		}
	}

	errorCallback(error: any) {
		console.log("error callback");
	}

	onSuccessCallback(data: Autodesk.Viewing.PropertyResult) {
		if (data.externalId) {
			store.dispatch({
				type: "SET_EXTERNAL_ID",
				payload: data.externalId,
			});
		}
		for (let property of data.properties) {
			if (property.displayName === "p4bClassification") {
				store.dispatch({
					type: "SET_CLASSIFICATION",
					payload: new Classification({
						name: property.displayValue,
					}),
				});
			}
		}
	}
}
