import { UseCaseModelsExtension } from "../components/extensions/UseCaseModelsExtension";
import { ModelNode } from "./ApiService";

export class UseCaseModelsExtensionHelper {
	private static instance: UseCaseModelsExtensionHelper;
	extension: UseCaseModelsExtension | undefined;

	static getInstance(): UseCaseModelsExtensionHelper {
		if (!UseCaseModelsExtensionHelper.instance) {
			UseCaseModelsExtensionHelper.instance =
				new UseCaseModelsExtensionHelper();
		}
		return UseCaseModelsExtensionHelper.instance;
	}

	addExtension(extension: UseCaseModelsExtension) {
		this.extension = extension;
	}

	loadNewModels(models: ModelNode[]) {
		this.extension?.loadNewModels(models);
	}
	resizeViewer() {
		this.extension?.resizeViewer();
	}
}
