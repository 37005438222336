import {
	ProductProperties,
	PropertyDefinition2,
	PropertyDefinitionGroup,
	PropertyDefinitionGroup2,
	PropertyStorage,
	ValueDescriptor,
} from "../../../services/ApiService";
import React from "react";
import { SingleValueInputForm } from "./SingleValueInputForm";
import { EnumerationValueInputForm } from "./EnumerationValueInputForm";

interface Props {
	propertyStorage: PropertyStorage;
	propertyDefinitionGroup: PropertyDefinitionGroup;
	index: number;
	productProperties: ProductProperties | null;
	propertyName: string | undefined;
}

const getDefaultValueName = (
	propertyName: string,
	productProperties: ProductProperties,
	propertyDefinitionGroup: PropertyDefinitionGroup
) => {
	let defaultValueName: string = "";
	if (!productProperties.propertyDefinitionGroup) {
		return defaultValueName;
	}
	productProperties.propertyDefinitionGroup.forEach(
		(element: PropertyDefinitionGroup2) => {
			if (element.pdgName === propertyDefinitionGroup.pdgName?.bimq) {
				element.propertyDefinitions!.forEach(
					(propertyDefinition: PropertyDefinition2) => {
						if (propertyDefinition.bimq === propertyName) {
							defaultValueName = propertyDefinition.value!;
							return;
						}
					}
				);
			}
		}
	);
	return defaultValueName;
};

export const InputForm = (props: Props) => {
	let defaultValueName: string = "";
	if (props.propertyName && props.productProperties) {
		defaultValueName = getDefaultValueName(
			props.propertyName,
			props.productProperties,
			props.propertyDefinitionGroup
		);
	}

	if (!props.propertyDefinitionGroup.propertyDefinitions![props.index])
		return <div />;
	const valueDescriptor: ValueDescriptor | undefined =
		props.propertyDefinitionGroup.propertyDefinitions![props.index]
			.valueDescriptor;

	if (!valueDescriptor) return <div />;
	if (valueDescriptor.singleValueDescriptor)
		return (
			<SingleValueInputForm
				propertyStorage={props.propertyStorage}
				index={props.index}
				productProperties={props.productProperties}
				defaultValue={defaultValueName}
			/>
		);
	if (valueDescriptor.enumerationValueDescriptor)
		return (
			<EnumerationValueInputForm
				propertyStorage={props.propertyStorage}
				enumerationValueDescriptor={
					valueDescriptor.enumerationValueDescriptor
				}
				index={props.index}
				propertyDefinitionGroup={
					props.productProperties?.propertyDefinitionGroup
				}
				defaultValue={defaultValueName}
			/>
		);
	return <div />;
};
