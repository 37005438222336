import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";
import { InputForm } from "./Forms/InputForm";
import React from "react";
import {
	ProductProperties,
	PropertyDefinitionGroup,
	PropertyStorage,
} from "../../services/ApiService";

interface Props {
	propertyStorage: PropertyStorage;
	propertyDefinitionGroup: PropertyDefinitionGroup;
	productProperties: ProductProperties | null;
}

export const PropertyCollapsible = (props: Props) => {
	return (
		<TableContainer component={Paper}>
			<Table size="small" aria-label="a dense table">
				<TableBody>
					{props.propertyStorage?.properties?.map((value, index) => (
						<TableRow key={index}>
							<TableCell style={{ color: "#373D3F" }}>
								{value.propertyName}
							</TableCell>
							<TableCell>
								<InputForm
									propertyStorage={props.propertyStorage}
									propertyDefinitionGroup={
										props.propertyDefinitionGroup
									}
									index={index}
									productProperties={props.productProperties}
									propertyName={value.propertyName}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
